import styled from 'styled-components';
import WhatsAppLogo from '../../assets/image/whatsAppLtBco.png'


export const WAhref = styled.div`
  animation: pulse 1.2s infinite;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  border-radius: 50px;
  box-shadow: 1px 1px 2px #999;
  z-index:1000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    bottom: 50px;
    right: 50px;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(37, 211, 102, 0.0);
    }
  
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.0);
    }
`

export const Icone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: url(${WhatsAppLogo});
  background-size: cover;
  @media (min-width: 768px) {
    width: 42px;
    height: 42px;
  }
`