import React, { useEffect } from 'react';

import Banner from './banner';
import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';
import ProdutosBar from '../../components/ProdutosBar/produtosBar';
import Diferenciais from '../../components/Diferenciais/diferenciais';
import DistribuidoraBanner from '../../components/DistribuidoraBanner/distribuidoraBanner';
import DeliveryBanner from '../../components/DeliveryBanner/deliveryBanner';
import ContatoBanner from '../../components/ContatoBanner/contatoBanner';


export function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <FaleConoscoBar />
      <ProdutosBar />
      <Diferenciais />
      <DistribuidoraBanner />
      <DeliveryBanner />
      <ContatoBanner />
      <FaleConoscoBar />
    </>
  );
}