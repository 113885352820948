import React, { useEffect, useState, useRef } from 'react';

import {
  Container,
  MySlides,
  BigImg,
  ButtonNext,
  ButtonPrev,
  CaptionContainer,
  Caption,
  Text,
  CButton,
  Icone
} from './slider.styles';

import Phone from "../../assets/image/whatsAppLtBco.png";

export default function Slider({ slides, isMobile }) {
  const [curr, setCurr] = useState(0);
  const { length } = slides;

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() =>
      setCurr((curr) =>
        curr === length - 1 ? 0 : curr + 1
      ), 7000);
    return () => {
      resetTimeout();
    };
  }, [curr]);

  const goToNext = () => {
    setCurr(curr === length - 1 ? 0 : curr + 1);
  }

  const goToPrev = () => {
    setCurr(curr === 0 ? length - 1 : curr - 1);
  }

  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }

  return (
    <>
      {slides.map((s, i) => (
        <div key={s.id}>
          {i === curr && (
            <MySlides>
              <ButtonPrev onClick={goToPrev} >❮</ButtonPrev>
              <Container>
                <CaptionContainer>
                  <BigImg src={s.image} />
                  <Caption>{s.title}</Caption>
                  <Text>{s.text}</Text>
                  <CButton>
                    <Icone src={Phone} alt="Telefone" />
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=554140036539&text=Olá, quero fazer um pedido da promoção do site: ${s.title} por ${s.text}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >Pedir pelo Whats</a></CButton>
                </CaptionContainer>
                {isMobile ?
                  <>
                  </>
                  :
                  <>
                    <CaptionContainer>
                      <BigImg src={s.image2} />
                      <Caption>{s.title2}</Caption>
                      <Text>{s.text2}</Text>
                      <CButton>
                        <Icone src={Phone} alt="Telefone" />
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=55416502&text=Olá, quero fazer um pedido da promoção do site: ${s.title2} por ${s.text2}`}
                          style={{ textDecoration: "none", color: "white" }}
                        >Pedir pelo Whats</a></CButton>
                    </CaptionContainer>
                  </>
                }
              </Container>
              <ButtonNext onClick={goToNext} >❯</ButtonNext>
            </MySlides>
          )}
        </div>
      ))}
    </>
  );
}