import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  SubTitle,
  BarContainerInput,
  CButton,
  Icone
} from './facaSeuPedido.styles';

import Phone from "../../assets/image/phonePink.png";

export default function FacaSeuPedido() {

  const funcFaleConosco = () => {
    window.open(`https://api.whatsapp.com/send?phone=554140036502&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`, "_blank")
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#fa057e"} >
        <BarGrid>
          <BarContainerTitle>
            <Title>Peça pelo nosso WhatsApp e receba delivery, sem sair de casa!</Title>
            <SubTitle>Aberto até à 15:00h de segunda à sábado.</SubTitle>
          </BarContainerTitle>

          <BarContainerInput>
            <CButton onClick={funcFaleConosco}>
              <Icone src={Phone} alt="Telefone" />
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=554140036502&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`}
                style={{ textDecoration: "none", color: "white" }}
              >FAÇA SEU PEDIDO</a></CButton>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}