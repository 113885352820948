import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Leading,
  LeadingBigText,
  ButtonsDiv,
  CustomButton,
  PriceDivA,
  LeadingTextPriceA
} from './banner.styles';

export default function Banner() {

  const history = useHistory()

  return (
    <>
      <Leading>
        <LeadingBigText>Em Curitiba você tem a melhor</LeadingBigText>
        
        <PriceDivA>
          <LeadingTextPriceA>DISTRIBUIDORA DE BEBIDAS</LeadingTextPriceA>
        </PriceDivA>
        <ButtonsDiv>
          <CustomButton onClick={() => history.push('/sobrenos')}>SAIBA MAIS</CustomButton>
        </ButtonsDiv>

      </Leading>
    </>
  );
}