import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const TitleA = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin: 25px 15px 10px 15px;
  @media (min-width: 768px) {
    margin: 35px 0px 20px 0px;
    font-size: 40px;
  }
`

export const HrLine = styled.hr`
  width: 25%;
  border: 3px solid ${colors.pink};
  border-radius: 50px;
  @media (min-width: 768px) {
    width: 10%;
  }
`

export const Description = styled.p`
  word-break: break-word;
  text-align: center;
  font-size: 16px;
  color: white;
  margin: 10px 10px 25px 10px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 20px 0px 40px 0px;
  }
`

export const BarGrid = styled.div`
  width: 90%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const BarCard = styled.div`
  grid-column: span 3;
  display: block;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: url(${props => props.fundo});
  background-repeat: no-repeat;
  background-position: 95% 95%;
  background-size: 50%;
  background-color: white;
  height: 300px;
  margin: 0px 25px 0px 25px;
  @media (min-width: 768px) {
    height: 340px;
  }
`

export const DivText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SubTitleText = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.pink};
  margin: 10px 0px 15px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 10px 0px 15px 0px;
  }
`

export const MensagemText = styled.p`
  word-break: break-word;
  line-height: 21px;
  text-align: justify;
  font-size: 14px;
  margin: 0px 35px 0px 35px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 27px;
    margin: 0px 50px 0px 50px;
  }
`

export const Icones = styled.img`
  height: 50px;
  margin-bottom: 15px;
`