import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Grid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const Column = styled.div`
  grid-column: span 3;
  height: 370px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
  }
`

export const MaxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Container = styled.div`
  text-align: justify;
  padding: 0px 10px 0px 10px;
  @media (min-width: 768px) {
    padding: 0px 100px 0px 20px;
  }
`

export const ContainerIframe = styled.iframe`
  width: 340px;
  height: 340px;
  border: 0;
  @media (min-width: 768px) {
    width: 560px;
    height: 315px;
  } 
`

export const Title = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0px 20px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 0px;
    font-size: 40px;
    text-align: left;
  }
`

export const Description = styled.p`
  text-align: justify;
  word-break: break-word;
  font-size: 14px;
  margin: 0px 25px 15px 25px;
  @media (min-width: 768px) {
    margin: 0px 0px 15px 0px;
  }
`
export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: white;
  border: 3px ${colors.blueTheme} solid;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 25px 0px 0px 25px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    margin: 25px 0px 0px 0px;
    width: 50%;
    border-radius: 50px;
  };
  :hover{
    background: ${colors.blueTheme};
  }
`

export const Icone = styled.img`
  width: 12%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 12%;
  }
`