import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FilterContainer,
  BoxContainer,
  Title,
  CButton,
  Icone
} from './cookiesMajor.styles';

import StopSign from '../../assets/image/stopSign.png';

export function CookiesMajor() {

  const [accept, setAccept] = useState(false)

  useEffect(() => {
    checkTermsOfUse()
  }, []);

  function checkTermsOfUse() {
    let getLocalStorage = window.localStorage.getItem('MajorDigBebidas')
    if (!getLocalStorage || getLocalStorage == null) {
      return
    } else {
      setAccept(true)
    }
  };

  function acceptTermsOfUse() {
    window.localStorage.setItem('MajorDigBebidas', JSON.stringify("accept"))
    setAccept(true)
  };

  return (
    <>
    {
      !accept && (

        <FilterContainer>
        <BoxContainer>
          <Icone src={StopSign} />
          <Title>
            Atenção! O conteúdo deste site é direcionado para publicos maiores de 18 anos. Ao continuar você assume e concorda com esta condição.
          </Title>
          <CButton onClick={acceptTermsOfUse}>Continuar</CButton>
        </BoxContainer>
      </FilterContainer>
        )
      }
    </>
  );
}