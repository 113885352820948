import React, { createContext, useState, useContext } from "react";

const ShowContext = createContext();

export default function ShowProvider({ children }) {
  const [show, setShow] = useState(true);

  return (
    <ShowContext.Provider
      value={{
        show,
        setShow,
      }}
    >
      {children}
    </ShowContext.Provider>
  );
}

export function useShow() {
  const context = useContext(ShowContext);
  const { show, setShow } = context;
  return { show, setShow };
}
