import React from 'react';

import {
	Title,
	SubTitle,
	Main,
} from './banner.styles';

export default function Banner() {

	return (
		<>
			<Title>RESTAURANTE</Title>
			<Main>
				<SubTitle>Qualidade nas refeições</SubTitle>
				<SubTitle>Tudo preparado com muito sabor</SubTitle>
			</Main>
		</>
	)
}