import React, { useEffect } from 'react';

import Banner from './banner';
import LocalizacaoAll from '../../components/LocalizacaoAll/localizacaoAll';
import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';

export function Unidades() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <LocalizacaoAll />
      <FaleConoscoBar />
    </>
  );
}