import React, { useState, useEffect } from 'react';
import { useShopCart } from "../../context/shopCart";
import { useHistory } from 'react-router-dom';

import {
  Text
} from './carrinho.styles'

import Quantidade from './quantidade';
import { produtos } from '../../content/produtos';

export function Carrinho() {
  const { setShopCart } = useShopCart();
  const history = useHistory()

  const [show, setShow] = useState(false);
  const [carrinho, setCarrinho] = useState(false);
  const [total, setTotal] = useState();

  useEffect(() => {
    window.scrollTo(0, 0)
    var digCart = JSON.parse(localStorage.getItem('digCart'));
    if (digCart) {
      //atualizar localStorage
      for (var i = 0; i < digCart.length; i++) {
        //encontrar preco atualizado;
        var id = digCart[i].id
        var found = produtos.find((item) => item.id === id);
        digCart[i].price = found.price
      }
      localStorage.setItem('digCart', JSON.stringify(digCart));
      setCarrinho(digCart)
      doCalc()
      setShow(true)
    }
  }, []);

  function removeItemCart(params) {
    var digCart = JSON.parse(localStorage.getItem('digCart'));
    
    var newArray = digCart.filter((item) => item.id !== params);

    if (!newArray.length) {
      localStorage.removeItem('digCart')
      setCarrinho(false)
      setShopCart(false)
      setShow(false)
      doCalc()
    } else {
      localStorage.setItem('digCart', JSON.stringify(newArray));
      setCarrinho(newArray)
      doCalc()
    }
  }

  function doCalc() {
    var digCart = JSON.parse(localStorage.getItem('digCart'));

    var somaTotal = 0
    if (digCart) {
      for (var i = 0; i < digCart.length; i++) {
        //encontrar preco atualizado;
        var calculo = digCart[i].quant * digCart[i].price
        somaTotal = somaTotal + calculo;
      }
      somaTotal = somaTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      setTotal(somaTotal)
    } else {
      somaTotal = somaTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      setTotal(somaTotal)
    }
  }

  return (
    <>
      {show ?
        <>
          {carrinho.map((item) => (

            <div key={item.id}>
              <Text size={30}>
                {item.id} - {item.title}
              </Text>

              <Quantidade item={item} doCalc={doCalc} />

              <Text onClick={() => removeItemCart(item.id)}>
                X Clique aqui para remover este item
              </Text>
            </div>
          )
          )}

          <Text onClick={() => history.push('/produtos')}>
            ADICIONAR NOVO PRODUTO
          </Text>
        </>
        :
        <>
          <Text onClick={() => history.push('/produtos')}>
            O seu carrinho ainda está vazio, aproveite agora as nossas ofertas especiais! Clique aqui.
          </Text>
        </>
      }
      <Text size={40}>
        {total}
      </Text>
    </>
  );
}