import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import banner from '../../assets/image/sobre-page-bg-banner.jpeg';


export const Leading = styled.section`
  flex-direction: column;
  height: 170px;
  padding: 10px 40px 30px 20px;
  background: url(${banner}) top black no-repeat;
  background-size: 105%;
  color: white;
    @media (min-width: 700px) {
      padding: 0px 30px 30px 30px;
      height: 400px;
      display: flex;
      align-items: center;
      background: url(${banner}) top black no-repeat;
      background-size: 60%;
   }
`

export const PriceDivA = styled.div`
  width: 60%;
  @media (min-width: 700px) {
    width: 50%;
    align-self: start;
  }
` 

export const LeadingTextPriceA = styled.p`
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 6vw;
  text-shadow: 5px 5px 10px ${colors.gray2};
  margin: 50% 25% 0% 0%;
  @media (min-width: 700px) {
    margin: 25% 0% 0% 25%;
    font-size: 4vw;
  }
`