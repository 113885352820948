import { Switch } from "react-router-dom";
import DigRoute from "./helpers/DigRoute";

import { useState } from "react";

import { useShow } from "./context/show";

import {
  Home,
  SobreNos,
  Localizacao,
  Unidades,
  Produtos,
  Carrinho,
  Termos
} from "./pages";
import {
  Header,
  Footer,
  CookiesNotification,
  CookiesMajor,
  WhatsAppButton,
} from "./components";

import { Model } from "./landingPages";

function App() {
  const { show } = useShow();

  const whatsappDefault = {
    text: "Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!",
    number: "4140036539",
  };
  const [whatsapp, setWhatsapp] = useState(whatsappDefault);

  function ChangeWhatsapp(data) {
    let text = data.text ? data.text : whatsappDefault.text;
    let number = data.number ? data.number : whatsappDefault.number;
    setWhatsapp({ text, number });
  }

  return (
    <>
      <Header show={show} />

      <Switch>
        <DigRoute exact path="/" component={Home} />
        <DigRoute exact path="/sobrenos" component={SobreNos} />
        <DigRoute exact path="/localizacao" component={Localizacao} />
        <DigRoute exact path="/unidades" component={Unidades} />
        <DigRoute exact path="/produtos" component={Produtos} />
        <DigRoute exact path="/carrinho" component={Carrinho} />
        <DigRoute exact path="/termos-de-uso" component={Termos} />
        <DigRoute exact path="/:id">
          <Model whatsapp={ChangeWhatsapp} />
        </DigRoute>
      </Switch>

      <WhatsAppButton number={whatsapp.number} text={whatsapp.text} />
      <CookiesNotification />
      <CookiesMajor />
      <Footer show={show} />
    </>
  );
}

export default App;
