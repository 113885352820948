import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @media (min-width: 768px) {
      flex-direction: row;
    };
`

export const CButton = styled.button`
  text-align: start;
  pointer-events: auto;
  cursor: pointer;
  border: 3px ${colors.blueTheme} solid;
  border-radius: 15px;
  padding: 1rem 1rem;
  font-family: inherit;
  font-size: inherit;
  margin: 10px 0px 10px 0px;
  width: 75%;
  display: flex;
  align-items: center;
/*   justify-content: center; */
  @media (min-width: 768px) {
    margin: 10px 10px 10px 10px;
    width: 25%;
  };
  :hover{
    background: ${colors.blueTheme};
    color: ${colors.pink};
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 12px;
`