import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Flex = styled.div`
/*     display: flex; */
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: white;
    width: 100%;
    position: fixed;
    z-index: 998;
    bottom: 0;
`

export const BarGrid = styled.div`
  width: 90%;
  padding: 25px 0px;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerTitle = styled.div`
  width: 100%
  grid-column: span 3;
  @media (min-width: 768px) {
    display: block;
    grid-column: span 5;
  }
`

export const BarContainerInput = styled.div`
  width: 100%
  grid-column: span 3;
  text-align: center;
  @media (min-width: 768px) {
    display: block;
  }
`

export const Title = styled.p`
  text-align: justify;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  @media (min-width: 768px) {
    display: block;
    text-align: left;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: black;
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 20px 0px 0px 0px;;
  color: white;
  width: 50%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 50px;
    width: 70%;
  };
  :hover{
    opacity: 0.7;
  }
`