import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const BarGrid = styled.div`
  width: 90%;
  padding: 40px 0px;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerTitle = styled.div`
  text-align: center;
  width: 100%
  grid-column: span 3;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    display: block;
    grid-column: span 3;
    margin-bottom: 0;
  }
`

export const Title = styled.p`
  font-size: 21px;
  color: white;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const SubTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin: 15px 0px 0px 0px;
  @media (min-width: 768px) {
    margin: 5px 0px 0px 0px;
  }
`

export const BarContainerInput = styled.div`
  display: flex;
  justify-content: center;
  width: 100%
  grid-column: span 3;
  @media (min-width: 768px) {
    /* display: block; */
    grid-column: span 3;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: black;
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin-top: 20px;
  color: white;
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
    border-radius: 50px;
  };
  :hover{
    background: ${colors.blueTheme};
  }
`

export const Icone = styled.img`
  width: 12%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 6%;
  }
`