import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Title = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: 24px;
  margin: 25px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 70px 0px 0px 0px;
  }
`

export const TextDiv = styled.div`
  width: 100%;
  margin: 25px 0px 25px 0px;
  @media (min-width: 768px){
    width: 75%;
    margin: 50px 0px 50px 0px;
  }
`

export const Text = styled.p`
    text-align: justify;
    font-size: 14px;
    color: ${colors.gray3};
    margin: 5px 20px 5px 20px;
`