import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Nav = styled.div`
  padding: 0.3rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: black;
  @media (max-width: 768px) {
    padding: 0.3rem 2rem;

  }
`;

export const Nav3 = styled.div`
  padding: 0.3rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  background: -moz-linear-gradient(bottom,  rgba(137,255,241,0) 0%, rgba(0,0,0,1) 25%); /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(137,255,241,0)), color-stop(25%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 25%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 25%); /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 25%); /* IE10+ */
  background: linear-gradient(to top,  rgba(137,255,241,0) 0%,rgba(0,0,0,1) 25%); /* W3C */

  @media (max-width: 768px) {
    padding: 0.3rem 2rem;
  }
`;

export const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 4px;
    width: 25px;
    background: ${props => props.backgroundKey ? colors.blueTheme : colors.pink };
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MenuLink = styled.a`
  padding: 0.5rem 0rem 0.5rem 0rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: all 0.2s ease-in;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  } 
  &:hover {
    color: ${colors.blueTheme};
    font-weight: bold;
    text-decoration: none;
  }
`;

export const MenuLinkButton = styled.button`
  animation: ${props => props.hasCart ? 'pulse 2s infinite': ''};
  text-decoration: none;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
  
  color: ${props => props.hasCart ? colors.blueTheme : colors.pink};
  transition: all 0.2s ease-in;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 20px;
  /* margin-bottom: 0px; */
  border: 3px solid ${props => props.hasCart ? colors.pink : colors.gray2};
  border-radius: 50px;

  padding: 5px 5px 5px 5px;
  width: 170px;
  background-color: ${props => props.hasCart ? colors.pink : colors.white};
  @media (max-width: 768px) {
    font-size: 0.9rem;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    
  } 
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
  
  @keyframes pulse {
    0% {
      transform: ${props => props.hasCart ? 'scale(1)' : ''};
      box-shadow: ${props => props.hasCart ? `0 0 0 0 rgba(255, 255, 255, 0.7)` : ''};
    }
  
    70% {
      transform: ${props => props.hasCart ? 'scale(1)' : ''};
      box-shadow: ${props => props.hasCart ? `0 0 0 15px rgba(255, 255, 255, 0.0)`: ''};
    }
  
    100% {
      transform: ${props => props.hasCart ? 'scale(1)' : ''};
      box-shadow: ${props => props.hasCart ? `0 0 0 0 rgba(255, 255, 255, 0.0)`: ''};
    }
`;

export const Icone = styled.img`
  width: 16px;
  margin: 0px 5px 0px 0px;
  @media (min-width: 768px) {
    width: 18px;
  }
`

export const MenuLinkButton2 = styled.a`
  padding: 0.5rem 0rem 0.5rem 0rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: ${colors.pink};
  transition: all 0.2s ease-in;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 20px;
  border: 3px solid ${colors.pink};

  border-radius: 50px;
  padding: 5px 15px 5px 15px;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  } 
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
  }
`;

export const Logo = styled.img`
  height: 140px;
  @media (max-width: 768px) {
    height: 70px;
  }
`

