import React from 'react';

import {
  Leading,
  PriceDivA,
  LeadingTextPriceA
} from './banner.styles';

export default function Banner() {

  return (
    <>
      <Leading>
        <PriceDivA>
          <LeadingTextPriceA>LOCALIZAÇÃO</LeadingTextPriceA>
        </PriceDivA>
      </Leading>
    </>
  );
}