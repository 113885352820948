import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Grid = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const Column = styled.div`
  grid-column: span 3;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 3;
  }
`

export const MaxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerIframe = styled.iframe`
  width: 340px;
  height: 340px;
  border: 0;
  margin-top: 15px;
  @media (min-width: 768px) {
    width: 650px;
    height: 200px;
    margin-top: 0px;
  } 
`

export const CButton = styled.button`
  text-align: start;
  pointer-events: auto;
  cursor: pointer;
  border: 3px ${colors.blueTheme} solid;
  border-radius: 15px;
  padding: 1rem 1rem;
  font-family: inherit;
  font-size: inherit;
  margin: 10px 0px 10px 0px;
  width: 75%;
  display: flex;
  align-items: center;
/*   justify-content: center; */
  @media (min-width: 768px) {
    margin: 10px 0px 10px 0px;
    width: 50%;
  };
  :hover{
    background: ${colors.blueTheme};
    color: white;
  }
`

export const Icone = styled.img`
  width: 12%;
  margin: 0px 20px 0px 10px;
  @media (min-width: 768px) {
    width: 12%;
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 12px;
`