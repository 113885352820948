import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const Row = styled.div`
  display: flex;
  ${props => props.center ? 'align-items: center':''};
`

export const ColumnTitle = styled.div`
  text-align: right;
  width: 35%;
  height: 30px;
  background-color: ${props => props.color};
`

export const ColumnPrice = styled.div`
  text-align: right;
  width: 35%;
`

export const ColumnDescribe = styled.div`
  width: 60%;
  margin-left: 15px;
`

export const Title = styled.h1`
  color: ${props => props.color};
  font-style: italic;
  font-size: 35px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
`

export const TextPrice = styled.p`
  color: ${colors.blueTheme};
  font-weight: bold;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`
export const TextName = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const TextDescription = styled.p`
  color: ${colors.gray2};
  font-style: italic;
  font-size: 14px;
  margin-top: 3px;
  margin-right: 10px;
  text-align: justify;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const RowBebidas = styled.div`
  display: flex;
  flex-direction: column;
  background-color: blue;
  border-radius: 25px;
  margin: 40px 15px 40px 15px;
  padding-bottom: 25px;
`

export const ColumnBebidasTitle = styled.div`
  text-align: right;
  width: 35%;
`

export const RowBebidasTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: blue;
  border-radius: 25px;
`

export const RowBebidasList = styled.div`
  display: flex;
  flex-direction: row;
  background-color: blue;
  border-radius: 25px;
`