import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import banner from '../../assets/image/home-page-bg-banner.png';


export const Leading = styled.section`
  flex-direction: column;
  height: 170px;
  padding: 10px 40px 30px 20px;
  background: url(${banner}) top black no-repeat;
  background-size: 100%;
  color: white;
  text-shadow: 0px 0px 5px #000;
    @media (min-width: 700px) {
      padding: 0px 30px 30px 30px;
      height: 750px;
      display: flex;
      align-items: center;
      background: url(${banner}) top black no-repeat;
      background-size: 100%;
   }
`

export const LeadingBigText = styled.p`
  margin: 0% 35% 0% 0%;
  color: ${colors.pink};
  font-weight: 200;
  font-size: 5vw;
  @media (min-width: 700px){
    margin: 5% 50% 0% 0%;
    font-weight: 200;
    font-size: 4vw;
  } 
  @media (min-width: 1500px){
    margin: 9% 50% 0% 0%;
    font-weight: 200;
    font-size: 3vw;
  }
`

export const PriceDivA = styled.div`
  /* position: relative; */
  width: 60%;
  @media (min-width: 700px) {
    width: 50%;
    align-self: start;
  }
` 

export const LeadingTextPriceA = styled.p`
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 6vw;
  text-shadow: 0px 10px 5px #000;
  margin: 1% 25% 0% 0%;
  @media (min-width: 700px) {
    margin: 5% 5% 0% 0%;
    font-size: 4vw;
  }
`

export const ButtonsDiv = styled.div`
  width: 210px;
  @media (min-width: 700px) {
    width: 240px;
    align-self: start;
    margin: 3% 0% 1% 20%; 
  }
`

export const CustomButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  border: 3px solid ${colors.blueTheme};
  background: none;
  border-radius: 50px;
  padding: 10px;
  font-size: 10;
  font-weight: 900;
  margin: 10px 0px 0px 0px;
  color: white;
  width: 60%;
  position: relative;
  display: inline-block;
  :hover{
    opacity: 0.7;
  }
  @media (min-width: 700px) {
    padding: 20px;
    font-size: 18;
  }
`