import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerVideo = styled.div`
  grid-column: span 3;
  height: 270px;
  display: flex;
  margin: 40px 0px 10px 0px;;
  @media (min-width: 768px) {
    margin-top: 25px;
    height: 400px;
  }
`

export const Card = styled.div`
  width: 100%;
  height: 210px;
  background: url(${props => props.url}) center no-repeat;
  background-size: 85%;
  @media (min-width: 768px) {
    background-size: 85%;
    height: 370px;
  }
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    grid-column: span 3;
  }
`

export const Title = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0px 20px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 25px;
    font-size: 40px;
    text-align: left;
  }
`

export const SubTitle = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 16px;
  margin: 0px 10px 20px 10px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 0px;
    font-size: 21px;
  }
`

export const Description = styled.p`
  text-align: justify;
  word-break: break-word;
  font-size: 14px;
  margin: 0px 25px 15px 25px;
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: black;
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin-top: 20px;
  color: white;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 30%;
    border-radius: 50px;
  };
  :hover{
    background: ${colors.blueTheme};
  }
`

export const Icone = styled.img`
  width: 12%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 12%;
  }
`