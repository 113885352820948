import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Grid,
  Column,
  MaxContainer,
  ContainerIframe,
  Container,
  Title,
  Description,
  CButton,
  Icone
} from './localizacaoMapa.styles';

import MapMarker from "../../assets/image/mapMarker.png";

export default function LpMapa() {

  const hasMapa = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.103536978552!2d-49.32256358498424!3d-25.501593283755525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce32bef9434a9%3A0x9b387b25eae6555d!2sRua%20Guilherme%20Fugmann%2C%2057%20-%20Cidade%20Industrial%20De%20Curitiba%2C%20Curitiba%20-%20PR%2C%2081305-670!5e0!3m2!1spt-BR!2sbr!4v1633019535047!5m2!1spt-BR!2sbr"

  const seeLocal = () => {
    window.open("https://maps.google.com/?q=Rua%20Guilherme%20Fugmann,%2057,%20CIC,%20Curitiba,%20PR", "_blank")
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Grid>
          <Column>
            <MaxContainer>
              <ContainerIframe src={hasMapa}
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></ContainerIframe>
            </MaxContainer>
          </Column>

          <Column>
            <MaxContainer>
              <Container>
                <Title>LOCALIZAÇÃO</Title>
                <Description>A distribuidora está localizada no <strong>bairro CIC</strong> em Curitiba, próximo a Electrolux Small Appliances. Visite nossa estrutura e conheça a variedades de produtos nacionais e importados que trabalhamos!</Description>
                <Description>Conheça também as outras <a href="/unidades" style={{ textDecoration: "none", color: "#2853ea" }}><strong>UNIDADES</strong></a> e serviços da Dig Bebidas & Drinks.</Description>
                <CButton onClick={seeLocal}>
              <Icone src={MapMarker} alt="Telefone" />
              <a
                rel="noreferrer"
                target="_blank"
                href="https://maps.google.com/?q=Rua%20Guilherme%20Fugmann,%2057,%20CIC,%20Curitiba,%20PR"
                style={{ textDecoration: "none", color: "black" }}
              >COMO CHEGAR</a></CButton>
              </Container>
            </MaxContainer>
          </Column>
        </Grid>
      </Flex>
    </>
  );
}