import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const BarGrid = styled.div`
  width: 90%;
  padding: 70px 0px;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerTitle = styled.div`
  text-align: center;
  width: 100%
  grid-column: span 2;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    display: block;
    grid-column: span 2;
    margin-bottom: 0;
  }
`

export const Title = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 40px;
    text-align: left;
  }
`

export const SubTitle = styled.p`
  font-size: 16px;
  color: ${colors.pink};
  margin: 20px 10px 20px 10px;
  @media (min-width: 768px) {
    margin: 25px 0px 25px 0px;
    font-size: 21px;
  }
`

export const Text = styled.p`
  line-height: 18px;
  text-align: justify;
  font-size: 14px;
  margin: 15px 12px 0px 12px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 27px;
    margin: 5px 0px 0px 0px;
  }
`

export const BarContainerInput = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  grid-column: span 4;
  padding: 0px;
  @media (min-width: 768px) {
    grid-column: span 4;
    padding: 0px 50px 0px 50px;
  }
`