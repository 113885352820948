import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  TitleA,
  HrLine,
  Description,
  BarGrid,
  BarCard,
  MainDiv,
  DivText,
  Icones,
  SubTitleText,
  MensagemText,
} from './diferenciais.styles';

import FundoDiferenciais from '../../assets/image/fundoDiferenciais.jpeg';
import ClockBlack from '../../assets/image/clockBlack.png';
import ClockGray from '../../assets/image/clockGray.png';
import TruckBlack from '../../assets/image/truckBlack.png';
import TruckGray from '../../assets/image/truckGray.png';
import ThumbGray from '../../assets/image/thumbGray.png';
import ThumbBlack from '../../assets/image/thumbBlack.png';


export default function BannerA() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={`url(${FundoDiferenciais})`} style={{ padding: "25px 0px" }} >
        <TitleA>DIFERENCIAIS</TitleA>
        <HrLine />
        <Description>Somos uma empresa sólida e consciente,<br></br>que acredita na marca e na qualidade<br></br>dos produtos e serviços oferecidos,<br></br>trazendo em nossa essência o respeito<br></br>aos nos clientes.</Description>

        <BarGrid>
          <BarCard>
            <MainDiv fundo={ClockGray}>
              <DivText>
                <Icones src={ClockBlack} />
                <SubTitleText>
                  ABERTO<br></br>TODOS OS DIAS
                </SubTitleText>
                <MensagemText>
                Não deixe acabar as bebidas! A <strong>Dig Bebidas & Drinks</strong> não descansa. Estamos à disposição para atende-lo todos os dias.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv fundo={ThumbGray}>
              <DivText>
                <Icones src={ThumbBlack} />
                <SubTitleText>
                  OS MELHORES<br></br>PREÇOS
                </SubTitleText>
                <MensagemText>
                Trabalhamos com as melhores marcas e os melhores preços do mercado. Fale conosco e confira nossas ofertas e promoções.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

          <BarCard>
            <MainDiv fundo={TruckGray}>
              <DivText>
                <Icones src={TruckBlack} />
                <SubTitleText>
                  ENTREGA<br></br>RÁPIDA
                </SubTitleText>
                <MensagemText>
                Contamos com uma equipe profissional capacitada para realizar a entrega dos seus produtos com agilidade e segurança.
                </MensagemText>
              </DivText>
            </MainDiv>
          </BarCard>

        </BarGrid>
      </Flex>
    </>
  );
}