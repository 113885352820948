import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerTitle,
  Title,
  SubTitle,
  Text,
  BarContainerInput
} from './produtosBar.styles';

import SlideProdutos from '../../components/SlideProdutos/carousel';

export default function AgendeBar() {
  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>
          <BarContainerTitle>
            <Title>BEBIDAS PARA VENDA OU CONSUMO</Title>
            <SubTitle>Atendimento na cidade de Curitiba e Região Metropolitana. Solicite seu pedido!</SubTitle>
            <Text>Temos como opções: cerveja, vinho, whisky, cachaça, vodka, gim e muito mais. Na <strong>Dig Bebidas & Drinks</strong> você encontra uma ampla variedade de produtos, tudo pelo melhor preço, com ofertas e promoções sob consulta. Confira!!!</Text>
          </BarContainerTitle>

          <BarContainerInput>
            <SlideProdutos />
          </BarContainerInput>
        </BarGrid>

      </Flex>
    </>
  );
}