import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const BarContainerArea = styled.div`
  grid-column: span 3;  
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    grid-column: span 3;
    height: 600px;
  }
`

export const Title = styled.p`
  color: white;
  word-break: break-word;
  font-size: 40px;
  font-weight: 900;
  margin: 0px 0px 20px 25px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 100px;
    font-size: 60px;
    text-align: left;
  }
`

export const SubTitle = styled.p`
  color: white;
  word-break: break-word;
  font-size: 21px;
  margin: 25px 0px 20px 25px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 100px;
    font-size: 28px;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: black;
  border: 3px solid ${colors.pink};
  border-radius: 50px;
  padding: 1rem 1.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin-top: 20px;
  color: white;
  width: 50%;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 30%;
    margin-left: 100px;
    border-radius: 50px;
  };
  :hover{
    background: ${colors.blueTheme};
  }
`

export const Icone = styled.img`
  width: 14%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 13%;
  }
`