import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const BarCard = styled.div`
  grid-column: span 3;
  display: block;
  margin-bottom: 100px;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const MainDivImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${props => props.heightM};
  background-color: white;
  margin: 0px 25px 0px 25px;
  @media (min-width: 768px) {
    height: ${props => props.heightD};
  }
`

export const MainDivText = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => props.heightM};
  background-color: white;
  margin: 0px 25px 0px 25px;
  @media (min-width: 768px) {
    height: ${props => props.heightD};
  }
`

export const DivRow = styled.div`
  width:100%;
  height: ${props => props.heightM};
  background: url(${props => props.fundo});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${props => props.cor};
  @media (min-width: 768px) {
    height: ${props => props.heightD};
  }
`
export const ImgProduct = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const HrLine = styled.hr`
  width: 25%;
  border: 3px solid ${colors.pink};
  border-radius: 50px;
  @media (min-width: 768px) {
    width: 10%;
  }
`

export const TextTitle = styled.p`
  font-size: 30px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
`

export const TextPrice = styled.p`
  color: grey;
  font-size: 22px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const TextDescription = styled.p`
text-align: center;
`

export const ButtonCart = styled.button`
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: ${colors.pink};
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin: 0px;
  :hover{
    opacity: 0.7;
  }
`
