import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card
} from './sobreRestaurante.styles';

import MeatImg from '../../assets/image/meat-dish.png';

export default function SobreRestaurante() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} >
        <BarGrid>

          <BarContainerArea>
            <Title>TRADIÇÃO CULINÁRIA</Title>
            <SubTitle>Experiência Completa do Início ao Fim</SubTitle>
            <Description>A <strong>Dig Restaurante</strong> faz uma fusão de cozinhas e pratos e consegue reunir elementos de várias tradições culinárias, são algumas das características da Dig Restaurante, que também oferece um ambiente limpo e acolhedor onde você pode realizar suas refeições utilizando nosso Wi-Fi gratuito.</Description>
            <Description>Os produtos utilizados são selecionados e de qualidade para o preparo do menu e nossos colaboradores são cuidodosos, trazendo em nossa essência o respeito aos nossos clientes.</Description>
          </BarContainerArea>

          <BarContainerVideo>
            <Card url={MeatImg}></Card>
          </BarContainerVideo>

        </BarGrid>
      </Flex>
    </>
  );
}