import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const BarGrid = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 40px 0px 40px 0px;
  }
`

export const BarContainerVideo = styled.div`

  grid-column: span 3;
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Card = styled.div`
  width: 100%;
  height: 400px;
  background: url(${props => props.url}) center no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    
    
  }
`

export const BarContainerArea = styled.div`

  grid-column: span 3;  
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.p`
  color: ${colors.pink};
  text-align: center;
  word-break: break-word;
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0px 20px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 0px;
    font-size: 40px;
  }
`

export const SubTitle = styled.p`
  text-align: center;
  word-break: break-word;
  font-size: 16px;
  margin: 0px 10px 20px 10px;
  @media (min-width: 768px) {
    margin: 0px 0px 20px 0px;
    font-size: 21px;
  }
`

export const Description = styled.p`
  text-align: justify;
  word-break: break-word;
  font-size: 14px;
  margin: 0px 25px 15px 25px;
`
