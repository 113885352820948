import React, { useEffect } from 'react';

import { Flex, Title, TextDiv, Text } from './termos.styles.js';
import Banner from './banner';

import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';

export function Termos() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Flex JustifyContent={"center"} FlexDirection={"column"} >
        <Title>TERMOS DE USO e POLÍTICA DE PRIVACIDADE</Title>
        <TextDiv>
          <Text>
            Termos e Condições
          </Text>
          <Text>
            Nossa política de privacidade se aplica a informações que coletamos quando você usa o site https://digbebidas.com.br. Podemos mudar esta política de privacidade de tempos em tempos, sempre que entendermos que melhorias precisam ser feitas para melhor uso e segurança da plataforma.
          </Text>
          <Text>
            Por isso recomendamos rever nossa política de privacidade sempre que acessar nossos serviços para manter-se informado sobre as nossas práticas de informação.
          </Text>
          <Text>
            Coleta de Informações
          </Text>
          <Text>
          Os dados e informações serão obtidos quando os USUÁRIOs interagirem com as diversas funcionalidades existentes no PLATAFORMA, fornecendo as informações voluntariamente, através do nosso WhatsApp por exemplo.
          </Text>
          <Text>
            Uso da Informação
          </Text>
          <Text>
            Nós não usamos as suas informações para anúncios publicitários de terceiros.
          </Text>
          <Text>
          Todos os dados e informações coletados dos USUÁRIOs serão incorporados ao banco de dados do PLATAFORMA, sendo sua responsável a DIG Bebidas.
          </Text>
          <Text>
            Ao acessar e utilizar nossos serviços, você concorda com o processamento de suas informações.
          </Text>
          <Text>
            Compartilhamento de Informações
          </Text>
          <Text>
          Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessadas por pessoas qualificadas e autorizadas pela DIG Bebidas.
          </Text>
          <Text>
            Fale com a gente
          </Text>
          <Text>
            Se você tem alguma dúvida mande um e-mail para dig@digbebidas.com.br.
          </Text>
          <Text>
            TERMO DE USO E RESPONSABILIDADE
          </Text>
          <Text>
            O presente Termo de Uso (“TERMO”) rege a relação havida entre DIG BEBIDAS Victor Leandro da Silva 14261778955, pessoa jurídica de direito privado, inscrita no CNPJ sob o no 39.944.898/0001-91 (“EMPRESA”), o Visitante (“USUÁRIO”) e, ao acessar a “PLATAFORMA”, você concorda integralmente com suas regras.
          </Text>
          <Text>
            USUÁRIO
          </Text>
          <Text>
            1.1. O USUÁRIO, na nossa definição, é quem interage com as funcionalidades da PLATAFORMA;
          </Text>
          <Text>
            1.2. A PLATAFORMA fornece informações sobre os serviços disponibilizados pela EMPRESA.
          </Text>
          <Text>
            1.3. Ao acessar a PLATAFORMA o USUÁRIO aceita, automática e incondicionalmente, este TERMO, comprometendo-se a cumpri-lo, invariavelmente.
          </Text>
          <Text>
            1.4. O USUÁRIO poderá tirar suas dúvidas sobre este TERMO entrando em contato com a EMPRESA pelos meios disponibilizados no SITE.
          </Text>
          <Text>
            1.5. Para utilizar a PLATAFORMA, o USUÁRIO deve ter 18 (dezoito) anos ou mais.
          </Text>
          <Text>
            1.6. A EMPRESA declara que, segundo seu julgamento e possibilidades de mercado, realizará atualização constante da PLATAFORMA visando ao seu aperfeiçoamento e adaptação às novas tecnologias disponíveis.
          </Text>
          <Text>
            1.7. O USUÁRIO, por sua vez, reconhece que a área de tecnologia da informação está sempre em constante desenvolvimento e evolução, e que a perfeição e/ou ausência de defeitos ou riscos é um conceito que não existe nesse setor.
          </Text>
          <Text>
            COOKIES
          </Text>
          <Text>
            2.1. O PLATAFORMA poderá fazer o uso de cookies, cabendo aos USUÁRIO configurarem o seu navegador de Internet, caso deseje bloqueá-los. Nesta hipótese, algumas funcionalidades do PLATAFORMA poderão ser limitadas.
          </Text>
          <Text>
            2.2. Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo usuário, no entanto, respeitarão sempre aos termos desta política e as opções do usuário a respeito de sua coleta e armazenamento.
          </Text>
          <Text>
          LEI APLICÁVEL E JURISDIÇÃO
          </Text>
          <Text>
            3.1. A presente Política de Privacidade será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de São Paulo no estado de São Paulo para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.;
          </Text>
        </TextDiv>
      </Flex>
      <FaleConoscoBar />
    </>
  );
}