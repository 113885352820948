import React, { useState } from 'react';

import {
  Flex,
  CButton,
  Container
} from './categorias.styles';

export default function Categorias({ filter }) {

  const [active, setActive] = useState()

  const getFilter = (tipo, item) => {
    setActive(item)
    filter(tipo)
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <CButton onClick={() => getFilter("Destilados", 1)} style={{ background: `${active === 1 ? "#2853ea" : "white"}` }}>
          <Container>
            <strong>DESTILADOS</strong><br></br>Vodkas, wiskis, cachaças...
          </Container>
        </CButton>

        <CButton onClick={() => getFilter("Energéticos", 2)} style={{ background: `${active === 2 ? "#2853ea" : "white"}` }}>
          <Container>
            <strong>ENERGÉTICOS</strong><br></br>RedBull, Monster...
          </Container>
        </CButton>
      </Flex>
    </>
  );
}