import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card,
  CButton,
  Icone

} from './distribuidoraBanner.styles';

import JackDanielsGlass from '../../assets/image/jackDanielsGlass.jpeg';
import Glass from "../../assets/image/glass.png";

export default function ExamesBanner() {
  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <BarGrid>

          <BarContainerVideo>
            <Card url={JackDanielsGlass}></Card>
          </BarContainerVideo>

          <BarContainerArea>
            <Title>DISTRIBUIDORA EM <span style={{ color: "#fa057e" }}>CURITIBA</span></Title>
            <SubTitle>Empresa inovadora<br></br>no seguimento de bebidas!</SubTitle>
            <Description>A <strong>Dig Bebidas & Drinks</strong> é uma distribuidora de bebidas e drinks em geral, que atua na cidade de Curitiba e Região Metropolitana. Contamos com um vasto rol de produtos, entre drinks, bebidas alcoólicas e não alcoólicas, produtos para narguilé, cigarros, carvão e gelo.</Description>
            <Description>Somos uma <strong>empresa sólida e consciente</strong>, acreditando na marca e na qualidade dos produtos e serviços oferecidos, trazendo em nossa essência o respeito aos nossos clientes.</Description>

            <CButton onClick={() => history.push('/sobrenos')}>
              <Icone src={Glass} alt="Telefone" />
              <a
                href="/sobrenos"
                style={{ textDecoration: "none", color: "white" }}
              >SOBRE NÓS</a></CButton>
          </BarContainerArea>

        </BarGrid>
      </Flex>
    </>
  );
}