var menu = [
    {
      id: 1,
      price: "R$14,90",
      title: "Frango Grelhado",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), filé de frango grelhado, farofa, fritas e salada.",
      grupo: "Prato",
    },
    {
      id: 2,
      price: "R$15,90",
      title: "Frango à Milanesa",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), filé de frango à milanesa, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 3,
      price: "R$16,90",
      title: "Frango à Parmegiana",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), filé de frango à parmegiana, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 4,
      price: "R$14,90",
      title: "Bisteca Grelhada",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bisteca grelhada, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 5,
      price: "R$16,90",
      title: "Bife Grelhado",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bife grelhado, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 6,
      price: "R$16,90",
      title: "Bife à Milanesa",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bife a milanesa, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 7,
      price: "R$16,90",
      title: "Bife Acebolado",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bife acebolado, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 8,
      price: "R$16,90",
      title: "Bife à Cavalo",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bife, ovo, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 9,
      price: "R$17,90",
      title: "Bife à Parmegiana",
      description: "Arroz, feijão preto, macarrão (alho e óleo ou sugo), bife à parmegiana, farofa, fritas, salada.",
      grupo: "Prato",
    },
    {
      id: 10,
      price: "R$14,90",
      title: "Strogonoff de Frango",
      description: "Arroz, strogonoff de frango, batata palha.",
      grupo: "Prato",
    },
    {
      id: 11,
      price: "R$16,90",
      title: "Strogonoff de Carne",
      description: "Arroz, strogonoff de carne, batata palha.",
      grupo: "Prato",
    },
    {
      id: 12,
      price: "R$15,00",
      title: "Porção de Fritas",
      description: "500g",
      grupo: "Porcao",
    },
    {
      id: 13,
      price: "R$17,00",
      title: "Frango à Passarinho",
      description: "500g",
      grupo: "Porcao",
    },
    {
      id: 14,
      price: "R$19,90",
      title: "Calabresa Acebolada",
      description: "500g",
      grupo: "Porcao",
    },
    {
      id: 15,
      price: "R$49,90",
      title: "Chuleta na Chapa",
      description: "(serve até duas pessoas)",
      grupo: "Porcao",
    },
    {
      id: 16,
      price: "R$53,90",
      title: "Picanha na Chapa",
      description: "(serve até duas pessoas)",
      grupo: "Porcao",
    },
    {
      id: 17,
      price: "R$4,50",
      title: "Refrigerante Lata",
      grupo: "Bebidas",
    },
    {
      id: 18,
      price: "R$6,50",
      title: "Refrigerante 600ml",
      grupo: "Bebidas",
    },
    {
      id: 19,
      price: "R$8,50",
      title: "Refrigerante 1 litro",
      grupo: "Bebidas",
    },
    {
      id: 20,
      price: "R$12,00",
      title: "Refrigerante 2 litros",
      grupo: "Bebidas",
    },
    {
      id: 21,
      price: "R$5,00",
      title: "Suco Natural de Laranja",
      grupo: "Bebidas",
    },
    {
      id: 22,
      price: "R$3,50",
      title: "Água com Gás",
      grupo: "Bebidas",
    },
    {
      id: 23,
      price: "R$3,50",
      title: "Água sem Gás",
      grupo: "Bebidas",
    }
  ];

  export {menu}