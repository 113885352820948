import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card
} from './sobreNosBanner.styles';

import SobreNosImg from '../../assets/image/sobreNosImg.jpeg';
import Glass from "../../assets/image/glass.png";

export default function ExamesBanner() {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <BarGrid>

          <BarContainerVideo>
            <Card url={SobreNosImg}></Card>
          </BarContainerVideo>

          <BarContainerArea>
            <Title>SOBRE NÓS</Title>
            <SubTitle>Dig Bebidas & Drinks</SubTitle>
            <Description>A <strong>Dig Bebidas & Drinks</strong> é uma distribuidora de bebidas e drinks em geral, que atua na cidade de Curitiba e Região Metropolitana. Contamos com um vasto rol de produtos, entre drinks, bebidas alcoólicas e não alcoólicas, produtos para narguilé, cigarros, carvão e gelo.</Description>
            <Description>Somos uma empresa sólida e consciente, acreditando na marca e na qualidade dos produtos e serviços oferecidos, trazendo em nossa essência o respeito aos nossos clientes.</Description>
            <Description><strong>Missão:</strong> Ser uma empresa inovadora no seguimento de bebidas, atendendo os nossos clientes com respeito e rapidez, buscando sua total satisfação.</Description>
            <Description><strong>Visão:</strong> Ser referência na qualidade dos serviços prestados e no bom relacionamento com o cliente.</Description>
            <Description><strong>Valores:</strong> Prezamos pela ética, transparência, responsabilidade com o meio ambiente, agilidade e respeito a todos os clientes.</Description>
          </BarContainerArea>

        </BarGrid>
      </Flex>
    </>
  );
}