import styled from "styled-components"

export const Bottom = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 5px 0px 5px 0px;
`

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin: 0;
`

//

export const ContainerArea = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  padding: 5px 0px 5px 0px;
  text-align: center;
`

export const BarGrid = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const BarCard = styled.div`
  grid-column: span 3;
  height: 350px;
  display: block;
  @media (min-width: 768px) {
    grid-column: span 1;
    display: block;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-fill-available;
  background: url(${props => props.url ? props.url : "" }) center no-repeat;
  background-size: 70%;
  @media (min-width: 768px) {
    background-size: 50%;
}
`

export const Container = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin: 5px 0px 10px 0px;
  @media (min-width: 768px) {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px 10px 0px;
  }
`

export const TextDescription = styled.p`
  font-size: 14px;
  color: white;
  text-align: justify;
  margin: 5px 30px 5px 30px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const DataArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 9px;
  padding-left: 30px;
`

export const Icone = styled.img`
  width: 7%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 5%;
  }
`

export const TextRef = styled.a`
  text-align: start;
  text-decoration: none;
  color: white;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`