import React, { useState, useEffect } from 'react';
import { useShopCart } from "../../context/shopCart";

import {
  Text
} from './carrinho.styles'

export default function Quantidade({ item, doCalc }) {
  const { shopCart } = useShopCart();

  const [numero, setNumero] = useState();
  const [displayPrice, setDisplayPrice] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    setNumero(item.quant)
    //get PRICE
    gettingPrice()
  }, [])

  function gettingPrice() {
    var valorUni = item.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setDisplayPrice(valorUni)

    //getting SubTotal        
    var subTotal = item.price * item.quant
    subTotal = subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setTotal(subTotal)
  }

  function plusQuant(params) {
    var plus = numero + 1
    setNumero(plus)
    var subTotal = params.price * plus
    subTotal = subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    setTotal(subTotal)

    var arr = JSON.parse(localStorage.getItem('digCart'));
    arr.find((o, i) => {
      if (o.id === params.id) {
        arr[i].quant = arr[i].quant + 1;
        return true;
      }
    });
    localStorage.setItem('digCart', JSON.stringify(arr));
    doCalc()
  }

  function minusQuant(params) {
    var minus = numero - 1
    if (minus === -1) return
    setNumero(minus)

    var subTotal = params.price * minus
    subTotal = subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    setTotal(subTotal)

    var arr = JSON.parse(localStorage.getItem('digCart'));
    arr.find((o, i) => {
      if (o.id === params.id) {
        arr[i].quant = arr[i].quant - 1;
        return true;
      }
    });
    localStorage.setItem('digCart', JSON.stringify(arr));
    doCalc()
  }

  return (
    <>
      <Text size={25}>
        <span onClick={() => plusQuant(item)}>+</span>
        <span style={{ fontWeight: "20px", cursor: "default" }}> {numero} </span>
        <span onClick={() => minusQuant(item)}>-</span>
      </Text>
      <Text size={25}>
        Valor unitário R${displayPrice}
      </Text>
      <Text size={25}>
        Sub-Total R${total}
      </Text>
    </>
  );
}