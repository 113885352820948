import React from "react";
import { Route } from "react-router-dom";
import { useShow } from "../context/show";

export default function DigRoute({ children, disableHeader, ...props }) {
  const { setShow } = useShow();

  if (disableHeader) {
    setShow(false);
  } else {
    setShow(true);
  }

  return <Route {...props}>{children}</Route>;
}
