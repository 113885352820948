import React, { useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Grid,
  Column,
  MaxContainer,
  ContainerIframe,
  CButton,
  Icone,
  Container
} from './localizacaoAll.styles';

import Bottle from "../../assets/image/bottle.png";
import BottleBlack from "../../assets/image/bottleBlack.png";
import Utensils from "../../assets/image/utensils.png";
import UtensilsBlack from "../../assets/image/utensilsBlack.png";
import Glass from "../../assets/image/glass.png";
import GlassBlack from "../../assets/image/glassBlack.png";

export default function LpMapa() {

  const [active, setActive] = useState()
  const [hasMapa, setHasMapa] = useState("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.103536978552!2d-49.32256358498424!3d-25.501593283755525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce32bef9434a9%3A0x9b387b25eae6555d!2sRua%20Guilherme%20Fugmann%2C%2057%20-%20Cidade%20Industrial%20De%20Curitiba%2C%20Curitiba%20-%20PR%2C%2081305-670!5e0!3m2!1spt-BR!2sbr!4v1633019535047!5m2!1spt-BR!2sbr")

  const seeLocal = (params) => {
    setActive(params)
    switch (true) {
      case params === 0:
        setHasMapa("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.103536978552!2d-49.32256358498424!3d-25.501593283755525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce32bef9434a9%3A0x9b387b25eae6555d!2sRua%20Guilherme%20Fugmann%2C%2057%20-%20Cidade%20Industrial%20De%20Curitiba%2C%20Curitiba%20-%20PR%2C%2081305-670!5e0!3m2!1spt-BR!2sbr!4v1633019535047!5m2!1spt-BR!2sbr");
        break;
      case params === 1:
        setHasMapa("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.0580479530686!2d-49.281796885067735!3d-25.469737083770145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce49ed0ea3109%3A0x40f377685ad4dddd!2sR.%20Santa%20Catarina%2C%201550%20-%20Gua%C3%ADra%2C%20Curitiba%20-%20PR%2C%2080630-120!5e0!3m2!1spt-BR!2sbr!4v1633030626036!5m2!1spt-BR!2sbr");
        break;
      case params === 2:
        setHasMapa("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.0683222372763!2d-49.281912385067784!3d-25.46939398377027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce49ecf77de8b%3A0x5563e023c35010bf!2sR.%20Santa%20Catarina%2C%201530%20-%20Gua%C3%ADra%2C%20Curitiba%20-%20PR%2C%2080630-120!5e0!3m2!1spt-BR!2sbr!4v1633030696977!5m2!1spt-BR!2sbr");
        break;
      case params === 3:
        setHasMapa("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.0683222372763!2d-49.281912385067784!3d-25.46939398377027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce49ecf77de8b%3A0x5563e023c35010bf!2sR.%20Santa%20Catarina%2C%201530%20-%20Gua%C3%ADra%2C%20Curitiba%20-%20PR%2C%2080630-120!5e0!3m2!1spt-BR!2sbr!4v1633030696977!5m2!1spt-BR!2sbr");
        break;

      default:
        setHasMapa("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.103536978552!2d-49.32256358498424!3d-25.501593283755525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce32bef9434a9%3A0x9b387b25eae6555d!2sRua%20Guilherme%20Fugmann%2C%2057%20-%20Cidade%20Industrial%20De%20Curitiba%2C%20Curitiba%20-%20PR%2C%2081305-670!5e0!3m2!1spt-BR!2sbr!4v1633019535047!5m2!1spt-BR!2sbr");
    }
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Grid>
          <Column>
            <MaxContainer>
              <CButton onClick={() => seeLocal(0)} style={{ background: `${active === 0 ? "#2853ea" : "white"}` }}>
                <Icone src={active === 0 ? BottleBlack : Bottle} alt="Distribuidora" />
                <Container>
                  <strong>DIG Distribuidora</strong><br></br>CIC
                </Container>
              </CButton>

              {/* <CButton onClick={() => seeLocal(1)} style={{ background: `${active === 1 ? "#2853ea" : "white"}` }}>
                <Icone src={active === 1 ? BottleBlack : Bottle} alt="Distribuidora" />
                <Container>
                  <strong>DIG Distribuidora</strong><br></br>VILA GUAÍRA
                </Container>
              </CButton> */}

              <CButton onClick={() => seeLocal(2)} style={{ background: `${active === 2 ? "#2853ea" : "white"}` }}>
                <Icone src={active === 2 ? UtensilsBlack : Utensils} alt="Restaurante" />
                <Container>
                  <strong>DIG Restaurante</strong><br></br>VILA GUAÍRA
                </Container>
              </CButton>

              {/* <CButton onClick={() => seeLocal(3)} style={{ background: `${active === 3 ? "#2853ea" : "white"}` }}>
                <Icone src={active === 3 ? GlassBlack : Glass} alt="Pub" />
                <Container>
                  <strong>DIG Pub</strong><br></br>VILA GUAÍRA
                </Container>
              </CButton> */}

            </MaxContainer>
          </Column>

          <Column>
            <MaxContainer>
              <ContainerIframe src={hasMapa}
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></ContainerIframe>
            </MaxContainer>
          </Column>

        </Grid>
      </Flex>
    </>
  );
}