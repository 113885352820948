import React, { createContext, useState, useContext } from "react";

const ShopCartContext = createContext();

export default function ShowProvider({ children }) {
  const [shopCart, setShopCart] = useState(false);

  return (
    <ShopCartContext.Provider
      value={{
        shopCart,
        setShopCart,
      }}
    >
      {children}
    </ShopCartContext.Provider>
  );
}

export function useShopCart() {
  const context = useContext(ShopCartContext);
  const { shopCart, setShopCart } = context;
  return { shopCart, setShopCart };
}
