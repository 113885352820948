import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import banner from '../../assets/image/restaurante-page-bg-banner.png';


export const Main = styled.div`
  background: url(${banner}) center black no-repeat;
  background-size: contain;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    height: 90vh; 
  }
`

export const Title = styled.h1`
text-align: center;
  font-size: 30px;
  color: ${colors.blueTheme};
  @media (min-width: 768px) {
    font-size: 45px;
  }
`

export const SubTitle = styled.h2`
text-align: center;
color: white;
  font-size: 25px;
  margin 20px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 35px;
    margin 30px 0px 30px 0px;
  }
`