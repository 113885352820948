import React, { useEffect, useState } from 'react';

import Banner from './banner';
import Categorias from '../../components/Categorias/categorias';
import ProdutosPage from '../../components/Produtos/produtos';
import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';

import {produtos} from '../../content/produtos';

export function Produtos() {

const [filterProducts, setFilterProducts] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    setFilterProducts(produtos)
  }, []);


  function filter(params){
    var newArray = produtos.filter((item) => item.grupo === params);
    setFilterProducts(newArray)
  }

  return (
    <>
      <Banner />
      <Categorias filter={filter} />
      {filterProducts && (<ProdutosPage filterProducts={filterProducts} />)}
      <FaleConoscoBar />
    </>
  );
}