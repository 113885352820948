import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerArea,
  Title,
  SubTitle,
  CButton,
  Icone
} from './deliveryBanner.styles';

import DeliveryBackground from '../../assets/image/deliveryBackground.jpeg';
import Phone from "../../assets/image/phoneWhite.png";

export default function ExamesBanner() {

  const funcFaleConosco = () => {
    window.open(`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`, "_blank")
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={`url(${DeliveryBackground}) center`}>
        <BarGrid>

          <BarContainerArea>
            <Title>
              <span style={{ color: "#fa057e" }}>DELIVERY</span><br></br>
              COM ENTREGA<br></br>
              RÁPIDA
            </Title>
            <SubTitle>A <strong>Dig Bebidas & Drinks</strong><br></br>
              trabalha com as melhores<br></br>
              marcas do mercado.</SubTitle>

            <CButton onClick={funcFaleConosco}>
              <Icone src={Phone} alt="Telefone" />
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`}
                style={{ textDecoration: "none", color: "white" }}
              >PEÇA JÁ</a></CButton>
          </BarContainerArea>

        </BarGrid>
      </Flex>
    </>
  );
}