import React from 'react';

import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  Title,
  SubTitle,
  Description,
  Card,
  CButton,
  Icone

} from './contatoBanner.styles';

import ContatoImg from '../../assets/image/contatoImg.jpeg';
import InfoIcon from "../../assets/image/infoIcon.png";

export default function ExamesBanner() {

  const funcFaleConosco = () => {
    window.open(`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`, "_blank")
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginTop: "40px", marginBottom: "40px" }}>
        <BarGrid>

          <BarContainerVideo>
            <Card url={ContatoImg}></Card>
          </BarContainerVideo>

          <BarContainerArea>
            <Title>NOSSO ATENDIMENTO</Title>
            <SubTitle>Atendemos a demanda do mercado e garantimos a satisfação dos nossos clientes.</SubTitle>
            <Description>Contamos com um vasto rol de produtos, entre drinks, bebidas alcoólicas e não alcoólicas, produtos para narguilé, cigarros, carvão e gelo.</Description>
            <Description><strong>Trabalhamos com as mais consagradas marcas,  proporcionando aos nossos clientes toda segurança e confiabilidade.</strong></Description>

            <CButton onClick={funcFaleConosco}>
              <Icone src={InfoIcon} alt="Telefone" />
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`}
                style={{ textDecoration: "none", color: "white" }}
              >MAIS INFORMAÇÕES</a></CButton>
          </BarContainerArea>

        </BarGrid>
      </Flex>
    </>
  );
}