import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: absolute;
  right: -100%;
  width: 100%;
  height: 100%;
   
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
    
  @-webkit-keyframes slide {
    100% { right: 0; }
  }
  
  @keyframes slide {
    100% { right: 0; }
  }
`

export const MySlides = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 360px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    height: 390px;
  }
`

export const BigImg = styled.img`
  vertical-align: middle;
  width: 180px;
  height: 180px;
  @media (min-width: 768px) {
    width: 240px;
    height: 240px;
  }
`

export const ButtonPrev = styled.a`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: ${colors.blueTheme};
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px;
  user-select: none;
  :hover {
    background-color: ${colors.blue2};
  }
`

export const ButtonNext = styled.a`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: ${colors.blueTheme};
  font-weight: bold;
  font-size: 20px;
  user-select: none;
  -webkit-user-select: none;
  right: 0;
  border-radius: 3px;
  :hover {
    background-color: ${colors.blue2};
  }
`

export const CaptionContainer = styled.div`
  text-align: center;
  padding: 0px 70px;
`

export const Caption = styled.h3`
  margin: 0px;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const Text = styled.p`
  font-size: 18px;
  font-weight: 900;
  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: ${colors.greenWAButton2};
  border: none;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin-top: 20px;
  color: white;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 0;
  };
  :hover{
    background: ${colors.blueTheme};
  }
`

export const Icone = styled.img`
  width: 12%;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 9%;
  }
`