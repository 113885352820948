import React, { useEffect, useState } from 'react';

import {
  BarContainerSlide,
} from './carousel.styles';

import Slider from './slider';

import RedBull from '../../../src/assets/image/bebidas/redBull.jpeg';
import Smirnoff from '../../../src/assets/image/bebidas/smirnoff.jpeg';
import Heineken from '../../../src/assets/image/bebidas/heineken.jpeg';
import Absolut from '../../../src/assets/image/bebidas/absolut.jpeg';

const defaultSlides = [
  {
    id: 1,
    title: "Red Bull",
    textTitle: "",
    text: "R$9,00",
    image: RedBull,

    title2: "Smirnoff",
    textTitle2: "",
    text2: "R$35,00",
    image2: Smirnoff
  },
  {
    id: 2,
    title: "Heineken",
    textTitle: "",
    text: "R$6,99",
    image: Heineken,

    title2: "Absolut",
    textTitle2: "",
    text2: "R$84,90",
    image2: Absolut
  },
  {
    id: 3,
    title: "Smirnoff",
    textTitle: "",
    text: "R$35,00",
    image: Smirnoff,

    title2: "Red Bull",
    textTitle2: "",
    text2: "R$9,00",
    image2: RedBull,
  },
  {
    id: 4,
    title: "Absolut",
    textTitle: "",
    text: "R$84,90",
    image: Absolut,

    title2: "Heineken",
    textTitle2: "",
    text2: "R$6,99",
    image2: Heineken,
  }
]

export default function CarouselExames() {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let isMobile = (width <= 768);

  return (
    <>
      <BarContainerSlide>
        <Slider slides={defaultSlides} isMobile={isMobile} />
      </BarContainerSlide>
    </>
  )
}