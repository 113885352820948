import React from 'react';

import {
  WAhref,
  Icone
} from './whatsAppButton.styles';

export function WhatsAppButton({number, text}) {

  return (
    <>
      <WAhref>
        <a
        rel="noreferrer"
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=55${number}&text=${text}`} style={{textDecoration: "none"}}
        >
        <Icone></Icone>
          </a>
      </WAhref>
    </>
  );
}