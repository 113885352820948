import React, { useState } from "react";

import {
  Nav,
  Hamburger,
  MenuLink,
  MenuLinkButton,
  Menu,
  Logo,
} from "./header.styled";

import Logomarca from "../../assets/digbebidas.png";
import IconCart from "../../assets/image/footerMail.png";

export function Header({ show }) {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {show && (
        <>
          <Nav>
            <a href="/" style={{ textDecoration: "none" }}>
              <Logo src={Logomarca} alt="DIG Bebidas" />
            </a>
            <Hamburger onClick={() => setIsOpen(!isOpen)} backgroundKey={isOpen}>
              <span />
              <span />
              <span />
            </Hamburger>
            <Menu isOpen={isOpen}>
              <MenuLink href="/">INÍCIO</MenuLink>
              <MenuLink href="/sobrenos">SOBRE NÓS</MenuLink>
              <MenuLink href="/localizacao">LOCALIZAÇÃO</MenuLink>
              <MenuLink href="/restaurante">RESTAURANTE</MenuLink>
              <MenuLinkButton
                rel="noreferrer"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`}>
                DELIVERY
              </MenuLinkButton>
            </Menu>
          </Nav>
        </>
      )}
    </>
  );
}
