import React, { useEffect, useState } from 'react';

import {
	Row,
	ColumnTitle,
	ColumnPrice,
	ColumnDescribe,
	Title,
	TextPrice,
	TextName,
	TextDescription,

	RowBebidas,
	ColumnBebidasTitle,
	RowBebidasTitle,
	RowBebidasList

} from './main.styles';

import { menu } from '../../content/menu';

export default function Banner() {

	const [pratos, setPratos] = useState();
	const [porcao, setPorcao] = useState();
	const [bebidas, setBebidas] = useState();

	useEffect(() => {
		separeItens()
	}, [])

	function separeItens() {

		var pratos = []
		var porcoes = []
		var bebidas = []
		for (let index = 0; index < menu.length; index++) {
			var prato = menu[index].grupo === "Prato";
			if (prato) {
				pratos.push(menu[index])
			}
			var porcao = menu[index].grupo === "Porcao";
			if (porcao) {
				porcoes.push(menu[index])
			}
			var bebida = menu[index].grupo === "Bebidas";
			if (bebida) {
				bebidas.push(menu[index])
			}
		}

		setPratos(pratos)
		setPorcao(porcoes)
		setBebidas(bebidas)
	}


	return (
		<>
			<Row center={true}>
				<ColumnTitle color={'#2853ea'}>
				</ColumnTitle>
				<ColumnDescribe>
					<Title color={'#fa057e'}>Pratos</Title>
				</ColumnDescribe>
			</Row>

			{pratos ?
				<>
					{pratos.map((item) => (
						<>
							<Row key={item.id}>
								<ColumnPrice>
									<TextPrice>{item.price}</TextPrice>
								</ColumnPrice>
								<ColumnDescribe>
									<TextName>{item.title}</TextName>
									<TextDescription>{item.description}</TextDescription>
								</ColumnDescribe>
							</Row>
						</>
					))}
				</>
				:
				<>
				</>
			}

			<Row center={true}>
				<ColumnTitle color={'#fa057e'}>
				</ColumnTitle>
				<ColumnDescribe>
					<Title color={'#2853ea'}>Porções e Chapas</Title>
				</ColumnDescribe>
			</Row>

			{porcao ?
				<>
					{porcao.map((item) => (
						<>
							<Row key={item.id}>
								<ColumnPrice>
									<TextPrice>{item.price}</TextPrice>
								</ColumnPrice>
								<ColumnDescribe>
									<TextName>{item.title}</TextName>
									<TextDescription>{item.description}</TextDescription>
								</ColumnDescribe>
							</Row>
						</>
					))}
				</>
				:
				<>
				</>
			}

			<RowBebidas>
				<RowBebidasTitle>

					<ColumnBebidasTitle>
						<Title color={'white'} style={{ margin: '25px 25px 25px 25px' }}>Bebidas</Title>
					</ColumnBebidasTitle>
					<ColumnDescribe>
					</ColumnDescribe>

				</RowBebidasTitle>


				{bebidas ?
					<>
						{bebidas.map((item) => (
							<>
								<RowBebidasList key={item.id}>
									<ColumnPrice>
										<TextPrice style={{ color: 'white' }}>{item.price}</TextPrice>
									</ColumnPrice>

									<ColumnDescribe>
										<TextName style={{ color: 'white' }}>{item.title}</TextName>
									</ColumnDescribe>
								</RowBebidasList>
							</>
						))}
					</>
					:
					<>
					</>
				}

			</RowBebidas>
		</>
	)
}