import React, { useEffect } from 'react';

import Banner from './banner';
import Sobrenos from '../../components/SobreNosBanner/sobreNosBanner';
import LocalizacaoMapa from '../../components/LocalizacaoMapa/localizacaoMapa';
import Diferenciais from '../../components/Diferenciais/diferenciais';
import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';

export function SobreNos() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <Sobrenos />
      <Diferenciais />
      <LocalizacaoMapa />
      <FaleConoscoBar />
    </>
  );
}