import React from "react";

import { Flex } from "../../assets/styles/global";
import {
  Bottom,
  BottomText,
  ContainerArea,
  BarGrid,
  BarCard,
  Card,
  Container,
  Description,
  DataArea,
  TextDescription,
  TextRef,
  Icone
} from "./footer.styles";
import LogoMarca from "../../assets/digbebidas.png";

import MapMarker from "../../assets/image/footerMapMarker.png";
import Mail from "../../assets/image/footerMail.png";
import Clock from "../../assets/image/footerClock.png";
import Phone from "../../assets/image/footerPhone.png";
import WhatsApp from "../../assets/image/footerWhatsApp.png";

export function Footer({ show }) {

  return (
    <>
      {show && (
        <>
          <Flex
            AlignItems={"center"}
            JustifyContent={"center"}
            FlexDirection={"row"}
          >
            <ContainerArea>
              <BarGrid>
                <BarCard>
                  <Card url={LogoMarca}></Card>
                </BarCard>

                <BarCard>
                  <Card>
                    <Container>
                      <Description>SERVIÇOS E BEBIDAS</Description>
                      <TextDescription>Somos uma empresa sólida e consciente, acreditando na marca e na qualidade dos produtos e serviços oferecidos, trazendo em nossa essência o respeito aos nossos clientes. Distribuidora de bebidas e drinks em geral, que atua na cidade de Curitiba e Região Metropolitana.</TextDescription>
                      <TextDescription>Contamos com um vasto rol de produtos, entre drinks, bebidas alcoólicas e não alcoólicas, produtos para narguilé, cigarros, carvão e gelo. Interessado em nossos produtos? Entre em contato com a Dig Bebidas & Drinks e solicite uma cotação.</TextDescription>
                      <TextDescription>
                        <a
                          style={{ textDecoration: "none", color: "white" }}
                          href="/termos-de-uso"
                        >TERMOS DE USO</a></TextDescription>
                    </Container>
                  </Card>
                </BarCard>

                <BarCard>
                  <Card>
                    <Container>
                      <Description>MAIS INFORMAÇÕES</Description>
                      <DataArea>
                        <Icone src={MapMarker} alt="Local" />
                        <TextRef
                          rel="noreferrer"
                          target="_blank"
                          href={`https://maps.google.com/?q=Rua%20Guilherme%20Fugmann,%2057,%20CIC,%20Curitiba,%20PR`}
                        >Rua Guilherme Fugmann, 57<br></br>Curitiba/PR (próximo ao Neoville-CIC)
                        </TextRef>
                      </DataArea>

                      <DataArea>
                        <Icone src={Mail} alt="Email" />
                        <TextRef
                          href="mailto:dig@digbebidas.com.br"
                          style={{ textDecoration: "none" }}
                        >dig@digbebidas.com.br
                        </TextRef>
                      </DataArea>

                      <DataArea>
                        <Icone src={Clock} alt="Horários" />
                        <TextRef>Aberto todos os dias<br></br>Entregamos em toda Curitiba e região.
                        </TextRef>
                      </DataArea>

                      <DataArea>
                        <Icone src={Phone} alt="Telefone" />
                        <span itemProp="telephone">
                          <TextRef
                            href="tel:4140036539"
                          >(41) <span style={{ fontWeight: "bold", fontSize: "21px" }}>4003-6539</span>
                          </TextRef>
                        </span>
                      </DataArea>

                      <DataArea>
                        <Icone src={WhatsApp} alt="WhatsApp" />
                        <TextRef
                          rel="noreferrer"
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=554140036539&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido!`}
                        >(41) <span style={{ fontWeight: "bold", fontSize: "21px" }}>4003-6539</span>
                          <br></br>
                          <span style={{ fontSize: "12px" }}>
                            Telefone WhatsApp
                          </span>
                        </TextRef>
                      </DataArea>

                    </Container>
                  </Card>
                </BarCard>
              </BarGrid>
            </ContainerArea>
          </Flex>

          <Bottom>
            <BottomText>&copy; 2022 | Desenvolvido por DIG Bebidas | CNPJ: 39.944.898/0001-91</BottomText>
          </Bottom>
        </>
      )}
    </>
  );
}
