import React, { useEffect } from 'react';

import Banner from './banner';
import LocalizacaoMapa from '../../components/LocalizacaoMapa/localizacaoMapa';
import FaleConoscoBar from '../../components/FaleConoscoBar/faleConoscoBar';

export function Localizacao() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <LocalizacaoMapa />
      <FaleConoscoBar />
    </>
  );
}