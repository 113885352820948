import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Flex,
  BarGrid,
  BarContainerTitle,
  Title,
  BarContainerInput,
  CButton
} from './cookiesNotification.styles';

export function CookiesNotification() {
  
  const [accept, setAccept] = useState(false)
  
  useEffect(() => {
    checkTermsOfUse()
  }, []);

  function checkTermsOfUse() {
    let getLocalStorage = window.localStorage.getItem('termsOfUseDigBebidas')
    if (!getLocalStorage || getLocalStorage == null) {
      return
    } else {
      setAccept(true)
    }
  };

  function acceptTermsOfUse(){
    window.localStorage.setItem('termsOfUseDigBebidas', JSON.stringify("accept"))
    setAccept(true)
  };

  return (
    <>
      <Flex style={{display: accept ? "none" : "flex" }}>
        <BarGrid>
          <BarContainerTitle>
            <Title>
              Este site usa cookies para melhorar sua experiência. Ao clicar em "Aceitar" você concorda com o uso dos cookies, termos e políticas do site.
              <span>
                <Link to='/termos-de-uso' style={{ textDecoration: "none", color:"#007bff" }}>
                &nbsp;Leia Mais
                </Link>
              </span>
               </Title>
          </BarContainerTitle>

          <BarContainerInput>
                <CButton onClick={acceptTermsOfUse}>Aceitar</CButton>
          </BarContainerInput>
        </BarGrid>
      </Flex>
    </>
  );
}