import absolut from '../assets/image/bebidas/absolut.jpeg';
import ballantines from '../assets/image/bebidas/ballantines.jpeg';
import redbull from '../assets/image/bebidas/redBull.jpeg';


var produtos = [
    {
      id: 1,
      title: "Absolut",
      img: absolut,
      price: 84.90,
      description: "0ml",
      grupo: "Destilados",
    },
    {
      id: 2,
      title: "Ballantines",
      img: ballantines,
      price: 12.00,
      description: "0ml",
      grupo: "Destilados",
    },
    {
      id: 3,
      title: "RedBull",
      img: redbull,
      price: 9.00,
      description: "0ml",
      grupo: "Energéticos",
    },
  ];

  export {produtos}