import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import unidades from "../../content/unidades.json";
import Restaurante from '../Restaurante/restaurante';
import Cardapio from '../Cardapio/cardapio';

export function Model({ whatsapp }) {
  const { id } = useParams();
  const history = useHistory();

  const [result, setResult] = useState();

  useEffect(() => {
    var data = unidades.find((unidade) => unidade.data.slug === id);
    if (!data) {
      history.push("/");
      return;
    }

    if (data.data.telefone_whats) {
      whatsapp({
        text: data.data.mensagem_whats,
        number: data.data.telefone_whats,
      });
    }
    setResult(data);

    switch (true) {
      case id === "restaurante":
        setResult('restaurante')
        break;

      case id === "cardapio":
        setResult('cardapio')
        break;

      default:

    }
  }, [id]);

  return (
    <>

      {result === 'restaurante' && (<Restaurante />)}
      {result === 'cardapio' && (<Cardapio />)}

    </>
  );
}
