import React, { useEffect } from 'react';

import Main from './main';
import FacaSeuPedido from '../../components/FacaSeuPedidoBar/facaSeuPedido';

export default function Restaurante() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Main />
      <FacaSeuPedido />
    </>
  );
}