import React, { useEffect } from 'react';

import Banner from './banner';
import SobreRestaurante from '../../components/SobreRestaurante/sobreRestaurante';
import LpMapa from '../../components/LocalizacaoMapaRestaurante/localizacaoMapa';
import FacaSeuPedido from '../../components/FacaSeuPedidoBar/facaSeuPedido';
import Cardapio from './cardapio';

export default function Restaurante() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Banner />
      <SobreRestaurante />
      <FacaSeuPedido />
      <LpMapa />
      <Cardapio />
      <FacaSeuPedido />
    </>
  );
}