import React from 'react';
import { useShopCart } from "../../context/shopCart";

import {
  BarCard,
  MainDivImg,
  MainDivText,
  DivRow,
  HrLine,
  TextTitle,
  TextPrice,
  TextDescription,
  ButtonCart,
  ImgProduct
} from './item.styles';


export default function Item({filterProducts}) {
  const { setShopCart } = useShopCart();

  function addToCart(params){
    params.quant = 1
    var digCart = JSON.parse(localStorage.getItem('digCart'));
    if(digCart){
      //find
      var found = digCart.find(item => item.id === params.id);
      if(!found){
          digCart.push(params)  
          localStorage.setItem('digCart', JSON.stringify(digCart));
        
      }else{
        alert("Este item já foi adicionado em seu carrinho")
        return
      }

    }else{
      localStorage.setItem('digCart', JSON.stringify([params]));
    }
    setShopCart(true)
    alert("Item adicionado com sucesso")
  }


  return (
    <>
      {filterProducts.map((number) => (
        <>
          <BarCard>

            <MainDivImg heightD="360px" heightM="240px">
              <ImgProduct src={number.img} />
            </MainDivImg>

            <MainDivText heightD="200px" heightM="210px">
              <DivRow id="row2" cor="white" heightD="200px" heightM="210px">

                <TextTitle>{number.title}</TextTitle>
                <TextPrice>R$ {number.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TextPrice>
                <HrLine />
                <TextDescription>{number.description}</TextDescription>
                <ButtonCart onClick={() => addToCart(number)}>Adicionar ao Carrinho</ButtonCart>
              </DivRow>
            </MainDivText>

          </BarCard>
        </>
      )
      )}
    </>
  );
}