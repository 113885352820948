import styled from 'styled-components';
import colors from '../../assets/styles/colors';


export const FilterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d( 0%, 0%, 0 );
  width: 100%;
  height: 100%;
  z-index: 30;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.2);
`

export const BoxContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 300px;
    height: 300px;
  }
`

export const Icone = styled.img`
  width: 25%;
  margin: 0px 20px 0px 10px;
  @media (min-width: 768px) {
    width: 25%;
  }
`

export const Title = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 5px;

`

export const CButton = styled.button`
  pointer-events: auto;
  cursor: pointer;
  background: red;
  border: none;
  border-radius: 50px;
  padding: 1rem 1.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin: 10px 0px 0px 0px;
  color: white;
  width: 50%;
  position: relative;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: -webkit-fill-available;
    margin: 10px 0px 0px 0px;
    border-radius: 50px;
    width: 70%;
  };
  :hover{
    opacity: 0.7;
  }
`