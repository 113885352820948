import React from 'react';

import {
	Title,
	Main,
} from './cardapio.styles';

export default function Banner() {
	const openMenu = () => {
		window.open("/cardapio", "_blank")
	}

	return (
		<>
			<Title>Nosso Cardápio</Title>
			<Main onClick={openMenu}></Main>
		</>)
}