import React from 'react';
import { Flex } from '../../assets/styles/global';

import {
  BarGrid,
} from './produtos.styles';

import FundoDiferenciais from '../../assets/image/fundoDiferenciais.jpeg';
import Item from './item';

export default function Products({filterProducts}) {

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} Background={`url(${FundoDiferenciais})`} style={{ marginTop: "50px", padding: "25px 0px" }} >
        <BarGrid>
          <Item filterProducts={filterProducts} />
        </BarGrid>
      </Flex>
    </>
  );
}