import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import banner from '../../assets/image/cardapioImg.png';


export const Main = styled.div`
  cursor: pointer;
  background: url(${banner}) center ${colors.blueTheme} no-repeat;
  background-size: 70%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  @media (min-width: 768px) {
    background-size: 30%;
    height: 45vh;
  }
`

export const Title = styled.h1`
text-align: center;
  font-size: 30px;
  color: ${colors.pink};
  @media (min-width: 768px) {
    font-size: 45px;
  }
`

export const SubTitle = styled.h2`
text-align: center;
color: white;
  font-size: 25px;
  margin 20px 0px 20px 0px;
  @media (min-width: 768px) {
    font-size: 35px;
    margin 30px 0px 30px 0px;
  }
`